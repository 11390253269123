import React, { FC } from "react"
import { graphql, PageProps } from "gatsby";
import { Button } from "@components";
import { BaseLayout } from "@layouts";
import { TypeImage } from "@types";
import { createLabel } from "@utilities";
import { motion } from "framer-motion";
import { GatsbyImage } from "gatsby-plugin-image";

type HomePageData = {
    allFile: {
        images: TypeImage[]
    }
}
type HomePageContext = {
    locale: string
}

const HomePage: FC<PageProps<HomePageData, HomePageContext>> = ( { data } ) => {
    const galleryPages = [ "beautiful-people", "eat-this", "go-there" ]
    return (
        <BaseLayout className={ "home" }>
            <h1 className={ "sr-only" }>Home</h1>
            { data.allFile.images.map( ( img, i ) => {
                return (
                    <motion.div initial={ { opacity: 0, y: 60 } } animate={ { opacity: 1, y: 0 } } transition={ { duration: .4, delay: .15 * i } } className={ "home__item" } key={ img.relativePath }>
                        <Button className={ "home__item__button" } look={ "primary" } to={ `/${ galleryPages[i] }` }>{ createLabel( galleryPages[i] ) }</Button>
                        <GatsbyImage className={ "bg-image__wrapper bg-image__wrapper--with-overlay" }
                                     imgClassName={ "bg-image__img" }
                                     image={ img.childImageSharp.gatsbyImageData }
                                     alt=""
                        />
                    </motion.div>
                )
            } ) }
        </BaseLayout>
    )
}

export default HomePage

export const query = graphql`{
    allFile(
        sort: {fields: [base]}
        filter: {extension: {regex: "/jpeg|jpg|png|gif/"}, relativeDirectory: {eq: "intro"}}
    ) {
        images: nodes {
            relativePath
            childImageSharp {
                original {
                    src
                }
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
        }
    }
}`
